<template>
    <div class="row mb-3">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Reflection </h2>
                    </div>
                </div>            
            </div> 
            <div class="row">
                <div class="col-12">
                    <splide :options="slide" @splide:move="checkSplide" @splide:scroll="checkSplide" @splide:scrolled="checkSplide" @splide:dragged="checkSplide" @splide:dragging="checkSplide" @splide:drag="checkSplide">
                        <splide-slide class="w-auto m-0"></splide-slide>
                        <splide-slide class="text-center h-auto ml-1">
                            <router-link :to="{name: 'MyCourseReflection', params: {idCourse: paramsId}, hash: '#open'}">
                                <div style="background-image: url(/images/video.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#open', 'border-0': tab != '#open'}" class="card p-4 p-md--5 pb-5 text-left shadow rounded-lg mb-5 h250">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Questionnaire<br>(Open-ended)</h1>    
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{name: 'MyCourseReflection', params: {idCourse: paramsId}, hash: '#closed'}">
                                <div style="background-image: url(/images/podcast.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#closed', 'border-0': tab != '#closed'}" class="card p-4 p-md--4 pt-5 pb-5 text-left shadow rounded-lg mb-5 h250">
                                    <div class="card-body py-4">
                                        <h2 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Questionnaire<br>(Closed-ended)</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{name: 'MyCourseReflection', params: {idCourse: paramsId}, hash: '#rating'}">
                                <div style="background-image: url(/images/presentation.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#rating', 'border-0': tab != '#rating'}" class="card p-4 p-md--5 pb-5 text-left shadow-lg rounded-lg mb-5 h250">
                                    <div class="card-body py-4">
                                        <h2 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Rating<br>Scale</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto mr-1">
                            <router-link :to="{name: 'MyCourseReflection', params: {idCourse: paramsId}, hash: '#interview'}">
                                <div style="background-image: url(/images/document.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#interview', 'border-0': tab != '#interview'}" class="card p-4 p-md--5 pb-5 text-left shadow rounded-lg mb-5 h250">
                                    <div class="card-body py-4">
                                        <h2 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Interview<br>Guide</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="w-auto m-0"></splide-slide>
                    </splide>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 p-0">
                    <div class="row" v-if="tab == '#open'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <Open></Open>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#closed'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <Closed></Closed>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#rating'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <Rating></Rating>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#interview'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <Interview></Interview>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>       
</template>

<script>
import Interview from './Sub/Interview.vue'
import Open from './Sub/Open.vue'
import Closed from './Sub/Closed.vue'
import Rating from './Sub/Rating.vue'
export default {
    name: `MyCourseReflection`,
    components:{
        Interview,
        Open,
        Closed,
        Rating,
    },
    mounted() {
        if (this.$route.hash) {
            this.isShow = true
            this.tab = this.$route.hash
        }
        this.checkSplide()
    },
    data(){
        return {
            tab: '',
            paramsId: this.$route.params.idCourse,
            slide: {
                start: this.$route.hash == '#open' ? 1 : this.$route.hash == '#closed' ? 2 : this.$route.hash == '#rating' ? 3 : this.$route.hash == '#interview' ? 4 : 1,
                rewind : true,
                perPage : 2,
                gap : '2rem',
                autoplay : false,
                arrows : true,
                pagination : false,
                breakpoints: {
                    '800' : {
                        perPage: 2,
                        gap: '10rem'
                    },
                    '640' : {
                        perPage: 2,
                        gap: '4rem'
                    },
                    '480' : {
                        perPage: 1,
                        gap: '2rem'
                    },
                }
            },
        }
    },
    methods:{
        checkSplide() {
            setTimeout(() => {
                let el = document.querySelectorAll('.splide__list .splide__slide')
                if(el) {
                    let length = el.length
                    let lastindex = length - 2;
                    let showPrev = el[1].classList.contains('is-visible')
                    let showNext = el[lastindex].classList.contains('is-visible')
                    if(showPrev) {
                        let prev = document.querySelector('.splide__arrow.splide__arrow--prev')
                        if(prev) {
                            prev.style.visibility = 'hidden'
                        }
                    } else {
                        let prev = document.querySelector('.splide__arrow.splide__arrow--prev')
                        if(prev) {
                            prev.style.visibility = 'inherit'
                        }
                    }
                    if(showNext) {
                        let next = document.querySelector('.splide__arrow.splide__arrow--next')
                        if(next) {
                            next.style.visibility = 'hidden'
                        }
                    } else {
                        let next = document.querySelector('.splide__arrow.splide__arrow--next')
                        if(next) {
                            next.style.visibility = 'inherit'
                        }
                    }
                }
            }, 500)
            
        },
        changeTab(i) {
            this.tab = i;
        }
    }
}
</script>