<template>
    <div class="row">
        <div class="col-12">
            <div class="row" v-if="!isLoad">
                <div class="col-12" v-if="questionnaire.length">
                    <div v-for="(item, i) in questionnaire" :key="i" class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row mb-1">
                            <div class="col-12">
                                <router-link :to="{name:'MyCourseReflectionRating', params:{idCourse: paramsId, idQuestionnaire: item.id}}" class="float-left mb-0 text-current fw-700">{{item.title | truncate(30, '...')}}</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="font-xssss fw-700 text-grey-600 d-block">{{item.description | truncate(50, '...')}}</span>
                            </div>
                        </div>
                        <div class="row float-right">
                            <div class="col-12 text-right">
                                <span class="font-xssss fw-600 text-grey-500 d-block mr-1 ">Created by : </span>
                                <small class="text-right">{{item.created.name}}</small>
                            </div>
                            <div class="col-12 text-right mt-2">
                                <router-link :to="{name:'MyCourseReflectionRating', params:{idCourse: paramsId, idQuestionnaire: item.id}}" class="btn btn-current">See More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            questionnaire: [],
        }
    },
    created(){
        this.getQuestionnaire()
    },
    methods:{
        async getQuestionnaire(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/reflection/questionnaire?slug=${this.paramsId}&type=3`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.questionnaire = res.data.data
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Rating Scale!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    },
}
</script>